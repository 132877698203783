import { IEventData } from "./interface";
import { getEventPublic, verifyDiscount } from "./service";
import { defineStore } from "pinia";
import { useRoute } from "vue-router";

const route = useRoute();
const params = route?.params?.id;

interface Participant {
	name: string;
	enterprise_mail: string;
	company_id: string;
}

export const useStorePreRegistration = defineStore("useStorePreRegistration", {
	state: () => ({
		isPaid: false,
		eventData: {
			address: "",
			assets: [
				{
					event_id: "",
					filename: "",
					path: "",
					type: "banner",
				},
			],
			event_term: {
				event_id: "",
				description: "",
			},
			id: "",
			is_paid: false,
			limit_date: "",
			online: false,
			payment_amount: "",
			commercial_partner_payment_amount: "",
			place: "",
			title: "",
			limit_date_format: "",
			coupon: "",
		} as IEventData,
		eventId: params?.toString(),
		isFutureTrends: false,
		financialRepresentative: {
			name: "",
			cpf: "",
			email: "",
			phone: "",
			company_id: "",
			position_id: "",
			foreign_document: false,
		},
		participantsBatch: [
			{
				name: "",
				enterprise_mail: "",
				company_id: "",
			},
		] as Participant[],
		registration_value: null as string | null,
		isCommercialPartner: false,
	}),

	actions: {
		async setEventData(data: IEventData) {
			this.eventData = data;
		},
		async setIsCommercialPartner(data: boolean) {
			this.isCommercialPartner = data;
		},

		setVerifyIfEventIsFutureTrends() {
			if (
				this.eventData.title.includes("FUTURE TRENDS") ||
				this.eventData.title.includes("Future Trends")
			) {
				this.isFutureTrends = true;
				return;
			}
		},

		setParticipantTickets(key?: string, value?: any) {
			if (!key) {
				this.participantsBatch = [] as Participant[];
				return;
			}

			const index = this.participantsBatch.findIndex(
				(participant: any) => participant.key === key
			);
			if (index !== -1) {
				this.participantsBatch[index] = {
					...this.participantsBatch[index],
					[key]: value,
				};
			} else {
				this.participantsBatch.push({ [key]: value } as Participant);
			}
		},

		setFinancialRepresentative(key?: string, value?: any) {
			if (!key) {
				this.financialRepresentative = this.financialRepresentative as any;
				return;
			}
			(this.financialRepresentative as any)[key] = value;
		},

		setValueInCompanyOptions(value: any) {
			(this.participantsBatch[0].company_id as any) = value;
		},

		async setValueInCompanyOptionsFinancial(value: any) {
			(this.financialRepresentative.company_id as any) = value;
			const data = {
				event_id: this.eventData.id,
				company_id: value,
				registrations_count: this.participantsBatch.length,
			};
			const res: any = await verifyDiscount(data, () => {});
			this.setRegistrationValue(res?.data?.registration_value);
			this.participantsBatch.forEach((participant) => {
				participant.company_id = value;
			});
		},

		setValueInPositionOptionsFinancial(value: any) {
			(this.financialRepresentative.position_id as any) = value;
		},
		setRegistrationValue(value: any) {
			(this.registration_value as any) = value;
		},

		setQuantityTicket(value: number) {
			this.participantsBatch.length = value;
		},
	},
});
